<template>
  <div
    class="login-wrapper lg:p-0 p-3"
    style="
      background-size: cover;
      min-height: 100vh;
      background-position: center center;
    "
  >
    <div class="container mx-auto">
      <div class="login-header">
        <router-link to="/Home"
          ><img src="@/assets/images/logo.png" alt="" class="logo"
        /></router-link>
        <router-link to="/" class="btn-default">
          <svg class="icon">
            <use xlink:href="#back"></use></svg
          >Back to Website
        </router-link>
      </div>
    </div>
    <div class="login-holder">
      <div class="container mx-auto">
        <div class="lg:flex items-center">
          <div class="lg:w-6/12">
            <h1 class="title">Welcome to the LDi Carrier Portal</h1>
            <img
              src="@/assets/images/wallet.svg"
              alt=""
              class="illustration lg:block hidden"
            />
          </div>
          <div class="lg:w-2/12"></div>
          <div class="lg:w-4/12">
            <form
              action="#"
              @submit.prevent="login('ltl')"
              class="form"
              v-if="loginType == 'ltl'"
            >
              <div class="form-group">
                <label>Email</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="v$.form.email.$model"
                />
                <div
                  class="input-errors"
                  v-for="(error, index) of v$.form.email.$errors"
                  :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="form-group">
                <label>Password</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="v$.form.password.$model"
                  placeholder="*********"
                />
                <div
                  class="input-errors"
                  v-for="(error, index) of v$.form.password.$errors"
                  :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="form-group checkbox-wrapper">
                <div @click="forgotModal" class="anchor">Forgot Password</div>
              </div>
              <button class="login-btn">Log In</button>
              <p>
               Don't have an account?<br>Please email carrierdev@shipldi.com
                <!-- <router-link to="/register" class="anchor"
                  >Register Here</router-link
                > -->
              </p>
              <div v-if="combinemessage" class="summary-err">
                {{ combinemessage }}
              </div>
            </form>
            <form
              action="#"
              @submit.prevent="login('/')"
              class="form"
              v-if="loginType == ''"
            >
              <div class="form-group">
                <label>Email</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="v$.form.email.$model"
                />
                <div
                  class="input-errors"
                  v-for="(error, index) of v$.form.email.$errors"
                  :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="form-group">
                <label>Password</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="v$.form.password.$model"
                />
                <div
                  class="input-errors"
                  v-for="(error, index) of v$.form.password.$errors"
                  :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="form-group checkbox-wrapper">
                <div @click="forgotModal" class="anchor">Forgot Password</div>
              </div>
              <button class="login-btn">Log In</button>
              <p>
               Don't have an account?<br>Please email carrierdev@shipldi.com
                <!-- <router-link to="/register" class="anchor"
                  >Register Here</router-link
                > -->
              </p>
              <div class="summary-err" v-if="combinemessage">
                {{ combinemessage }}
              </div>
            </form>
            <form
              action="#"
              @submit.prevent="login('factoring-company')"
              class="form"
              v-if="loginType == 'factoringcompany'"
            >
              <div class="form-group">
                <label>Email</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="v$.form.email.$model"
                />
                <div
                  class="input-errors"
                  v-for="(error, index) of v$.form.email.$errors"
                  :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="form-group">
                <label>Password</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="v$.form.password.$model"
                />
                <div
                  class="input-errors"
                  v-for="(error, index) of v$.form.password.$errors"
                  :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="form-group checkbox-wrapper">
                <div @click="forgotModal" class="anchor">Forgot Password</div>
              </div>
              <button class="login-btn">Log In</button>
              <p>
               Don't have an account?<br>Please email carrierdev@shipldi.com
                <!-- <router-link to="/register" class="anchor"
                  >Register Here</router-link
                > -->
              </p>
              <div v-if="combinemessage" class="summary-err">
                {{ combinemessage }}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ForgotDialogue ref="saveConfirmDialogue"></ForgotDialogue>
</template>
<script>
import { useRoute } from "vue-router";
import { mapActions, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { email, required, helpers } from "@vuelidate/validators";
import ForgotDialogue from "./ForgotDialogue.vue";

export default {
  name: "Login",
  components: {
    ForgotDialogue,
  },
  data() {
    return {
      v$: useVuelidate(),
      loginDetail: "",
      form: {
        email: "",
        password: "",
      },
      message: "",
      combinemessage: "",
      loginType: "",
      signInButtonText: "Sign In",
      errorUsername: "",
      errorPortalname: "",
      errorPassword: "",
      errorObj: "",
    };
  },
  validations() {
    return {
      form: {
        email: {
          required: helpers.withMessage("Email is required", required),
          email: helpers.withMessage("Please enter the correct email.", email),
        },
        password: {
          required: helpers.withMessage("Password is required", required),
        },
      },
    };
  },
  created: function () {
    this.route = useRoute();
    if (Object.keys(this.route.query).length != 0) {
      var l = this.route.query.l;
      if (l == "ltl") {
        this.loginType = "ltl";
      }
      if (l == "factoringcompany") {
        this.loginType = "factoringcompany";
      }
      if (l == "so") {
        return; // in case manual sign out do not do auto login
      }
    } else {
      return;
    }
  },
  methods: {
    forgotModal() {
      this.$refs.saveConfirmDialogue.show({
        title: this.message,
        filterName: this.IsSuccessful,
        okButton: "Login",
      });
    },
    async login(a) {
      this.v$.form.$touch();

      if (this.v$.form.$error) return;
      try {
        await this.TryDoLogin({
          email: this.form.email,
          password: this.form.password,
        });

        this.$router.push("" + a);
      } catch (err) {
        if (err.status === 403) {
          this.combinemessage = err.combinemessage;
          setTimeout(() => (this.combinemessage = ""), 5000);
        }
        console.log(err);
      }
    },
    ...mapActions("login", [
      "TryDoLogin",
      "TryLoginWithRefreshToken",
      "TryLoginWithAuthCode",
    ]),
  },
  computed: {
    ...mapGetters({
      initialLoginType: "environment/LoginType",
      apiKey: "environment/APIUrl",
      dataFetched: "environment/Fetched",
    }),
    isAuthed() {
      return this.$store.state.login.authed == true ? true : false;
    },
    customLoginLink() {
      return window.location.origin + "?l=form";
    },
    AADLoggingMessage() {
      return this.isAuthed == false
        ? "Click SignIn button to login again"
        : "Signing in ..";
    },
    authorizationCode() {
      let authorizationCode = "";
      if (Object.keys(this.route.query).length != 0) {
        authorizationCode = this.route.query.code;
      }
      return authorizationCode;
    },
  },
};
</script>
<style lang="scss">
.login-wrapper {
  background-image: #beff99;
  background-image: radial-gradient(
      at 28% 18%,
      hsla(252, 66%, 76%, 1) 0,
      transparent 48%
    ),
    radial-gradient(at 30% 86%, hsla(357, 70%, 79%, 1) 0, transparent 53%),
    radial-gradient(at 93% 81%, hsla(44, 64%, 73%, 1) 0, transparent 49%),
    radial-gradient(at 97% 90%, hsla(342, 72%, 71%, 1) 0, transparent 52%);
}
.login-header {
  padding: 20px 0;
  .logo {
    width: 60px;
  }
  display: flex;
  justify-content: space-between;
  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 10px;
  }
}
.login-holder {
  padding: 50px 0;
  label {
    font-weight: 700;
  }
  .form-control {
    margin: 0;
  }
  .input-errors {
    margin-top: 4px;
    font-size: 14px;
    color: #ff073a;
  }
}
.checkbox-wrapper {
  display: flex;
  justify-content: space-between;
}
.title {
  font-size: 36px;
  font-family: "montserrat", sans-serif;
  font-weight: 700;
  color: #181818;
  margin-bottom: 30px;
}
.illustration {
  width: 90%;
}
.btn-default {
  background: #dcdcdc;
  color: #181818;
  padding: 10px 20px;
  border-radius: 6px;
}
.login-btn {
  background: #65b0ba;
  color: #fff;
  display: block;
  width: 100%;
  padding: 10px 20px;
  border-radius: 6px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in;
  &:hover {
    box-shadow: 0 20px 30px -11px rgba(0, 0, 0, 0.2);
    transform: translate(0px, -5px);
  }
}
.summary-err {
  background: #f8e3df;
  color: #e9664d;
  font-size: 14px;
  border: 1px solid #e9664d;
  padding: 10px 10px;
  border-radius: 6px;
  margin-top: 5px;
}
.anchor {
  color: #65b0ba;
  font-weight: 700;
  cursor: pointer;
}
.combinemessage {
  background: rgba(255, 255, 255, 0.1);
  padding: 12px;
  border-radius: 6px;
  margin-top: 10px;
}
</style>
